import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import OurStory from "../components/our-story";
import OurTeam from "../components/our-team";
import Passionate from "../components/passionate-about";
import TrustedBy from "../components/trusted-by";

const AboutUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "about-us" }) {
				aboutUs {
					ourStory {
						ourStoryTitle
						ourStoryContent
						ourStoryTagline
						ourStoryHeading
						ourStoryContent2
						ourStoryItems {
							ourStoryItemContent
							ourStoryItemHeading
							outStoryItemImage {
								node {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: WEBP
												quality: 80
												transformOptions: { cropFocus: CENTER, fit: COVER }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
						backgroundImage {
							node {
								altText
								sourceUrl
							}
						}
					}
					passionateSection {
						passionateTitle
						passionateTagline
						passionateContent
						passionateItems {
							passionateItemHeading
							passionateItemContent
							passionateItemColor
						}
					}
					ourTeam {
						ourTeamHeading
						ourTeamTagline
						ourTeamContent
						ourTeamCta {
							target
							title
							url
						}
						selectOurTeamMembers {
							nodes {
								... on WpTeam {
									slug
									title
									teamPostFields {
										teamMemberDesignation
										teamMemberLinkedinUrl
										teamMemberFavoriteQuote
										teamMemberExperience
										teamMemberKeyAccomplishments
										teamMemberInterests
										teamMemberPets
										teamMemberImage {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
														)
													}
												}
											}
										}
									}
								}
							}
						}
					}
					ourCustomers {
						ourCustomerContent
						ourCustomerHeading
						selectOurCustomers {
							nodes {
								... on WpIndustry {
									title
									slug
									link
									industryPostFields {
										industryCardInfo {
											industryCaseStudyLink {
												nodes {
													slug
												}
											}
											industryIcon {
												node {
													altText
													sourceUrl
													localFile {
														childImageSharp {
															gatsbyImageData(
																formats: WEBP
																quality: 80
																transformOptions: {
																	cropFocus: CENTER
																	fit: COVER
																}
																layout: CONSTRAINED
																placeholder: BLURRED
															)
														}
													}
												}
											}
											cardColor
										}
									}
								}
							}
						}
					}
					trustedByAbout {
						trustedByHeading
						selectTrustedByLogos {
							nodes {
								... on WpTrustedByLogo {
									featureImage {
										featureImage {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: WEBP
															quality: 80
															transformOptions: {
																cropFocus: CENTER
																fit: CONTAIN
															}
															layout: CONSTRAINED
															placeholder: BLURRED
														)
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { aboutUs, seoFields },
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Our Story",
				item: {
					url: `${siteUrl}/our-story`,
					id: `${siteUrl}/our-story`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/our-story`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout isDefault={false}>
				<OurStory
					heading={aboutUs?.ourStory?.ourStoryTitle}
					content={aboutUs?.ourStory?.ourStoryContent}
					tagline={aboutUs?.ourStory?.ourStoryTagline}
					title2={aboutUs?.ourStory?.ourStoryHeading}
					content2={aboutUs?.ourStory?.ourStoryContent2}
					bgImage={aboutUs?.ourStory?.backgroundImage.node?.sourceUrl}
					ourStoryItems={aboutUs?.ourStory?.ourStoryItems}
				/>
				<Passionate
					heading={aboutUs?.passionateSection?.passionateTitle}
					tagline={aboutUs?.passionateSection?.passionateTagline}
					content={aboutUs?.passionateSection?.passionateContent}
					items={aboutUs?.passionateSection?.passionateItems}
				/>
				<OurTeam
					heading={aboutUs?.ourTeam?.ourTeamHeading}
					tagline={aboutUs?.ourTeam?.ourTeamTagline}
					content={aboutUs?.ourTeam?.ourTeamContent}
					ctaButton={aboutUs?.ourTeam?.ourTeamCta}
					teamsMember={aboutUs?.ourTeam?.selectOurTeamMembers?.nodes}
				/>
				{/* <OurCustomers
					heading={aboutUs?.ourCustomers?.ourCustomerHeading}
					content={aboutUs?.ourCustomers?.ourCustomerContent}
					customers={aboutUs?.ourCustomers?.selectOurCustomers?.nodes}
				/> */}
				<TrustedBy
					heading={aboutUs?.trustedByAbout?.trustedByHeading}
					logos={aboutUs?.trustedByAbout?.selectTrustedByLogos?.nodes}
				/>
			</Layout>
		</>
	);
};

export default AboutUsPage;
